<template>
  <k-vuelidate-error-extractor :validator="validator">
    <ckeditor
      v-bind="$attrs"
      :id="editorId"
      v-if="mounted"
      ref="ckeditor"
      :editor="editor"
      :value="value"
      @input="OnInput"
      @ready="OnEditorReady"
      @destroy="OnEditorDestroy"
      :config="config"
    ></ckeditor>
  </k-vuelidate-error-extractor>
</template>

<script>
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import List from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import HighlightPlugin from '@ckeditor/ckeditor5-highlight/src/highlight';
import HorizontalLinePlugin from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import Image from '@ckeditor/ckeditor5-image/src/image';
import Imageupload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Link from '@ckeditor/ckeditor5-link/src/link';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Codeblock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import Placeholder from 'ckeditor5-placeholder';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import Video from '@visao/ckeditor5-video/src/video';
import VideoUpload from '@visao/ckeditor5-video/src/videoupload';
import VideoToolbar from '@visao/ckeditor5-video/src/videotoolbar';
import VideoResize from '@visao/ckeditor5-video/src/videoresize';
import VideoStyle from '@visao/ckeditor5-video/src/videostyle';
import { FullScreen } from '../../plugins/ckeditor/fullScreen';
import { CKEditorUploadAdapter } from '../../utils/CKEditorUploadAdapter';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';

let ct = 0;

export default {
  name: 'basic-ckeditor',
  inheritAttrs: false,
  data() {
    return {
      mounted: false,
      editorId: 'k-basic-ckeditor-' + ct,
      editor: InlineEditor,
      config: null,
    };
  },
  async mounted() {
    this.config = {
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        ItalicPlugin,
        Underline,
        List,
        ParagraphPlugin,
        HighlightPlugin,
        HorizontalLinePlugin,
        Widget,
        FullScreen,
        Indent,
        IndentBlock,
        Heading,
        Image,
        Imageupload,
        ImageResize,
        ImageToolbar,
        ImageCaption,
        ImageStyle,
        MediaEmbed,
        Alignment,
        Table,
        TableToolbar,
        TableProperties,
        TableCellProperties,
        BlockQuote,
        Link,
        PasteFromOffice,
        RemoveFormat,
        Codeblock,
        Placeholder,
        Video,
        VideoToolbar,
        VideoResize,
        VideoStyle,
        VideoUpload,
      ],
      toolbar: [
        'heading',
        'fontFamily',
        'bold',
        'italic',
        'underline',
        'fontColor',
        'fontSize',
        'fontBackgroundColor',
        'removeFormat',
        '|',
        'outdent',
        'indent',
        'alignment',
        'numberedlist',
        'bulletedlist',
        'horizontalLine',
        '|',
        'link',
        'blockQuote',
        'insertTable',
        'imageUpload',
        'videoUpload',
        'mediaEmbed',
        'codeblock',
        '|',
        'undo',
        'redo',
        '|',
        'fullScreen',
        'placeholder',
      ],
      placeholderProps: {
        types: ['Nev', 'Email', 'BelsoLink', 'KulsoLink', 'Szervezet'],
      },
      placeholderBrackets: {
        open: '%',
        close: '%',
      },
      link: {
        addTargetToExternalLinks: false,
        decorators: {
          openInNewTab: {
            mode: 'manual',
            label: 'Megnyitás új lapon',
            defaultValue: true,
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          },
        },
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          // 'tableProperties',
          'tableCellProperties',
        ],
      },
      image: {
        styles: ['alignLeft', 'alignCenter', 'alignRight', 'side'],
        resizeOptions: [
          {
            name: 'resizeImage:original',
            value: null,
            icon: 'original',
          },
          {
            name: 'resizeImage:50',
            value: '50',
            icon: 'medium',
          },
          {
            name: 'resizeImage:75',
            value: '75',
            icon: 'large',
          },
        ],
        toolbar: [
          'imageStyle:alignLeft',
          'imageStyle:alignCenter',
          'imageStyle:alignRight',
          'imageStyle:side',
          '|',
          'resizeImage:50',
          'resizeImage:75',
          'resizeImage:original',
          '|',
          'imageTextAlternative',
        ],
      },
      mediaEmbed: {
        previewsInData: true,
      },
      fontFamily: {
        options: [
          'default',
          'Montserrat',
          'Poppins',
          'Raleway',
          'Roboto',
          'SF Pro',
        ],
      },
      fontSize: {
        supportAllValues: true,
        options: [10, 12, 14, 'default', 18, 20, 22, 24, 26, 28],
      },
      fontColor: {
        columns: 10,
        documentColors: 200,
        colors: [
          { color: 'hsl(6, 54%, 95%)', label: ' ' },
          { color: 'hsl(6, 54%, 89%)', label: ' ' },
          { color: 'hsl(6, 54%, 78%)', label: ' ' },
          { color: 'hsl(6, 54%, 68%)', label: ' ' },
          { color: 'hsl(6, 54%, 57%)', label: ' ' },
          { color: 'hsl(6, 63%, 46%)', label: ' ' },
          { color: 'hsl(6, 63%, 41%)', label: ' ' },
          { color: 'hsl(6, 63%, 35%)', label: ' ' },
          { color: 'hsl(6, 63%, 29%)', label: ' ' },
          { color: 'hsl(6, 63%, 24%)', label: ' ' },
          { color: 'hsl(6, 78%, 96%)', label: ' ' },
          { color: 'hsl(6, 78%, 91%)', label: ' ' },
          { color: 'hsl(6, 78%, 83%)', label: ' ' },
          { color: 'hsl(6, 78%, 74%)', label: ' ' },
          { color: 'hsl(6, 78%, 66%)', label: ' ' },
          { color: 'hsl(6, 78%, 57%)', label: ' ' },
          { color: 'hsl(6, 59%, 50%)', label: ' ' },
          { color: 'hsl(6, 59%, 43%)', label: ' ' },
          { color: 'hsl(6, 59%, 37%)', label: ' ' },
          { color: 'hsl(6, 59%, 30%)', label: ' ' },
          { color: 'hsl(283, 39%, 95%)', label: ' ' },
          { color: 'hsl(283, 39%, 91%)', label: ' ' },
          { color: 'hsl(283, 39%, 81%)', label: ' ' },
          { color: 'hsl(283, 39%, 72%)', label: ' ' },
          { color: 'hsl(283, 39%, 63%)', label: ' ' },
          { color: 'hsl(283, 39%, 53%)', label: ' ' },
          { color: 'hsl(283, 34%, 47%)', label: ' ' },
          { color: 'hsl(283, 34%, 40%)', label: ' ' },
          { color: 'hsl(283, 34%, 34%)', label: ' ' },
          { color: 'hsl(283, 34%, 28%)', label: ' ' },
          { color: 'hsl(282, 39%, 95%)', label: ' ' },
          { color: 'hsl(282, 39%, 89%)', label: ' ' },
          { color: 'hsl(282, 39%, 79%)', label: ' ' },
          { color: 'hsl(282, 39%, 68%)', label: ' ' },
          { color: 'hsl(282, 39%, 58%)', label: ' ' },
          { color: 'hsl(282, 44%, 47%)', label: ' ' },
          { color: 'hsl(282, 44%, 42%)', label: ' ' },
          { color: 'hsl(282, 44%, 36%)', label: ' ' },
          { color: 'hsl(282, 44%, 30%)', label: ' ' },
          { color: 'hsl(282, 44%, 25%)', label: ' ' },
          { color: 'hsl(204, 51%, 94%)', label: ' ' },
          { color: 'hsl(204, 51%, 89%)', label: ' ' },
          { color: 'hsl(204, 51%, 78%)', label: ' ' },
          { color: 'hsl(204, 51%, 67%)', label: ' ' },
          { color: 'hsl(204, 51%, 55%)', label: ' ' },
          { color: 'hsl(204, 64%, 44%)', label: ' ' },
          { color: 'hsl(204, 64%, 39%)', label: ' ' },
          { color: 'hsl(204, 64%, 34%)', label: ' ' },
          { color: 'hsl(204, 64%, 28%)', label: ' ' },
          { color: 'hsl(204, 64%, 23%)', label: ' ' },
          { color: 'hsl(204, 70%, 95%)', label: ' ' },
          { color: 'hsl(204, 70%, 91%)', label: ' ' },
          { color: 'hsl(204, 70%, 81%)', label: ' ' },
          { color: 'hsl(204, 70%, 72%)', label: ' ' },
          { color: 'hsl(204, 70%, 63%)', label: ' ' },
          { color: 'hsl(204, 70%, 53%)', label: ' ' },
          { color: 'hsl(204, 62%, 47%)', label: ' ' },
          { color: 'hsl(204, 62%, 40%)', label: ' ' },
          { color: 'hsl(204, 62%, 34%)', label: ' ' },
          { color: 'hsl(204, 62%, 28%)', label: ' ' },
          { color: 'hsl(168, 55%, 94%)', label: ' ' },
          { color: 'hsl(168, 55%, 88%)', label: ' ' },
          { color: 'hsl(168, 55%, 77%)', label: ' ' },
          { color: 'hsl(168, 55%, 65%)', label: ' ' },
          { color: 'hsl(168, 55%, 54%)', label: ' ' },
          { color: 'hsl(168, 76%, 42%)', label: ' ' },
          { color: 'hsl(168, 76%, 37%)', label: ' ' },
          { color: 'hsl(168, 76%, 32%)', label: ' ' },
          { color: 'hsl(168, 76%, 27%)', label: ' ' },
          { color: 'hsl(168, 76%, 22%)', label: ' ' },
          { color: 'hsl(168, 42%, 94%)', label: ' ' },
          { color: 'hsl(168, 42%, 87%)', label: ' ' },
          { color: 'hsl(168, 42%, 74%)', label: ' ' },
          { color: 'hsl(168, 42%, 61%)', label: ' ' },
          { color: 'hsl(168, 45%, 49%)', label: ' ' },
          { color: 'hsl(168, 76%, 36%)', label: ' ' },
          { color: 'hsl(168, 76%, 31%)', label: ' ' },
          { color: 'hsl(168, 76%, 27%)', label: ' ' },
          { color: 'hsl(168, 76%, 23%)', label: ' ' },
          { color: 'hsl(168, 76%, 19%)', label: ' ' },
          { color: 'hsl(145, 45%, 94%)', label: ' ' },
          { color: 'hsl(145, 45%, 88%)', label: ' ' },
          { color: 'hsl(145, 45%, 77%)', label: ' ' },
          { color: 'hsl(145, 45%, 65%)', label: ' ' },
          { color: 'hsl(145, 45%, 53%)', label: ' ' },
          { color: 'hsl(145, 63%, 42%)', label: ' ' },
          { color: 'hsl(145, 63%, 37%)', label: ' ' },
          { color: 'hsl(145, 63%, 32%)', label: ' ' },
          { color: 'hsl(145, 63%, 27%)', label: ' ' },
          { color: 'hsl(145, 63%, 22%)', label: ' ' },
          { color: 'hsl(145, 61%, 95%)', label: ' ' },
          { color: 'hsl(145, 61%, 90%)', label: ' ' },
          { color: 'hsl(145, 61%, 80%)', label: ' ' },
          { color: 'hsl(145, 61%, 69%)', label: ' ' },
          { color: 'hsl(145, 61%, 59%)', label: ' ' },
          { color: 'hsl(145, 63%, 49%)', label: ' ' },
          { color: 'hsl(145, 63%, 43%)', label: ' ' },
          { color: 'hsl(145, 63%, 37%)', label: ' ' },
          { color: 'hsl(145, 63%, 31%)', label: ' ' },
          { color: 'hsl(145, 63%, 25%)', label: ' ' },
          { color: 'hsl(48, 89%, 95%)', label: ' ' },
          { color: 'hsl(48, 89%, 90%)', label: ' ' },
          { color: 'hsl(48, 89%, 80%)', label: ' ' },
          { color: 'hsl(48, 89%, 70%)', label: ' ' },
          { color: 'hsl(48, 89%, 60%)', label: ' ' },
          { color: 'hsl(48, 89%, 50%)', label: ' ' },
          { color: 'hsl(48, 88%, 44%)', label: ' ' },
          { color: 'hsl(48, 88%, 38%)', label: ' ' },
          { color: 'hsl(48, 88%, 32%)', label: ' ' },
          { color: 'hsl(48, 88%, 26%)', label: ' ' },
          { color: 'hsl(37, 90%, 95%)', label: ' ' },
          { color: 'hsl(37, 90%, 90%)', label: ' ' },
          { color: 'hsl(37, 90%, 80%)', label: ' ' },
          { color: 'hsl(37, 90%, 71%)', label: ' ' },
          { color: 'hsl(37, 90%, 61%)', label: ' ' },
          { color: 'hsl(37, 90%, 51%)', label: ' ' },
          { color: 'hsl(37, 86%, 45%)', label: ' ' },
          { color: 'hsl(37, 86%, 39%)', label: ' ' },
          { color: 'hsl(37, 86%, 33%)', label: ' ' },
          { color: 'hsl(37, 86%, 27%)', label: ' ' },
          { color: 'hsl(28, 80%, 95%)', label: ' ' },
          { color: 'hsl(28, 80%, 90%)', label: ' ' },
          { color: 'hsl(28, 80%, 81%)', label: ' ' },
          { color: 'hsl(28, 80%, 71%)', label: ' ' },
          { color: 'hsl(28, 80%, 61%)', label: ' ' },
          { color: 'hsl(28, 80%, 52%)', label: ' ' },
          { color: 'hsl(28, 74%, 46%)', label: ' ' },
          { color: 'hsl(28, 74%, 39%)', label: ' ' },
          { color: 'hsl(28, 74%, 33%)', label: ' ' },
          { color: 'hsl(28, 74%, 27%)', label: ' ' },
          { color: 'hsl(24, 71%, 94%)', label: ' ' },
          { color: 'hsl(24, 71%, 88%)', label: ' ' },
          { color: 'hsl(24, 71%, 77%)', label: ' ' },
          { color: 'hsl(24, 71%, 65%)', label: ' ' },
          { color: 'hsl(24, 71%, 53%)', label: ' ' },
          { color: 'hsl(24, 100%, 41%)', label: ' ' },
          { color: 'hsl(24, 100%, 36%)', label: ' ' },
          { color: 'hsl(24, 100%, 31%)', label: ' ' },
          { color: 'hsl(24, 100%, 26%)', label: ' ' },
          { color: 'hsl(24, 100%, 22%)', label: ' ' },
          { color: 'hsl(192, 15%, 99%)', label: ' ' },
          { color: 'hsl(192, 15%, 99%)', label: ' ' },
          { color: 'hsl(192, 15%, 97%)', label: ' ' },
          { color: 'hsl(192, 15%, 96%)', label: ' ' },
          { color: 'hsl(192, 15%, 95%)', label: ' ' },
          { color: 'hsl(192, 15%, 94%)', label: ' ' },
          { color: 'hsl(192, 5%, 82%)', label: ' ' },
          { color: 'hsl(192, 3%, 71%)', label: ' ' },
          { color: 'hsl(192, 2%, 60%)', label: ' ' },
          { color: 'hsl(192, 1%, 49%)', label: ' ' },
          { color: 'hsl(204, 8%, 98%)', label: ' ' },
          { color: 'hsl(204, 8%, 95%)', label: ' ' },
          { color: 'hsl(204, 8%, 90%)', label: ' ' },
          { color: 'hsl(204, 8%, 86%)', label: ' ' },
          { color: 'hsl(204, 8%, 81%)', label: ' ' },
          { color: 'hsl(204, 8%, 76%)', label: ' ' },
          { color: 'hsl(204, 5%, 67%)', label: ' ' },
          { color: 'hsl(204, 4%, 58%)', label: ' ' },
          { color: 'hsl(204, 3%, 49%)', label: ' ' },
          { color: 'hsl(204, 3%, 40%)', label: ' ' },
          { color: 'hsl(184, 9%, 96%)', label: ' ' },
          { color: 'hsl(184, 9%, 92%)', label: ' ' },
          { color: 'hsl(184, 9%, 85%)', label: ' ' },
          { color: 'hsl(184, 9%, 77%)', label: ' ' },
          { color: 'hsl(184, 9%, 69%)', label: ' ' },
          { color: 'hsl(184, 9%, 62%)', label: ' ' },
          { color: 'hsl(184, 6%, 54%)', label: ' ' },
          { color: 'hsl(184, 5%, 47%)', label: ' ' },
          { color: 'hsl(184, 5%, 40%)', label: ' ' },
          { color: 'hsl(184, 5%, 32%)', label: ' ' },
          { color: 'hsl(184, 6%, 95%)', label: ' ' },
          { color: 'hsl(184, 6%, 91%)', label: ' ' },
          { color: 'hsl(184, 6%, 81%)', label: ' ' },
          { color: 'hsl(184, 6%, 72%)', label: ' ' },
          { color: 'hsl(184, 6%, 62%)', label: ' ' },
          { color: 'hsl(184, 6%, 53%)', label: ' ' },
          { color: 'hsl(184, 5%, 46%)', label: ' ' },
          { color: 'hsl(184, 5%, 40%)', label: ' ' },
          { color: 'hsl(184, 5%, 34%)', label: ' ' },
          { color: 'hsl(184, 5%, 27%)', label: ' ' },
          { color: 'hsl(210, 12%, 93%)', label: ' ' },
          { color: 'hsl(210, 12%, 86%)', label: ' ' },
          { color: 'hsl(210, 12%, 71%)', label: ' ' },
          { color: 'hsl(210, 12%, 57%)', label: ' ' },
          { color: 'hsl(210, 15%, 43%)', label: ' ' },
          { color: 'hsl(210, 29%, 29%)', label: ' ' },
          { color: 'hsl(210, 29%, 25%)', label: ' ' },
          { color: 'hsl(210, 29%, 22%)', label: ' ' },
          { color: 'hsl(210, 29%, 18%)', label: ' ' },
          { color: 'hsl(210, 29%, 15%)', label: ' ' },
          { color: 'hsl(210, 9%, 92%)', label: ' ' },
          { color: 'hsl(210, 9%, 85%)', label: ' ' },
          { color: 'hsl(210, 9%, 70%)', label: ' ' },
          { color: 'hsl(210, 9%, 55%)', label: ' ' },
          { color: 'hsl(210, 14%, 39%)', label: ' ' },
          { color: 'hsl(210, 29%, 24%)', label: ' ' },
          { color: 'hsl(210, 29%, 21%)', label: ' ' },
          { color: 'hsl(210, 29%, 18%)', label: ' ' },
          { color: 'hsl(210, 29%, 16%)', label: ' ' },
          { color: 'hsl(210, 29%, 13%)', label: ' ' },
        ],
      },
      codeBlock: {
        languages: [
          {
            language: 'plaintext',
            label: 'Egyszerű szöveg',
            class: 'code-plaintext',
          },
          { language: 'cs', label: 'C#', class: 'code-csharp' },
          { language: 'css', label: 'CSS', class: 'code-css' },
          { language: 'scss', label: 'SCSS', class: 'code-scss' },
          {
            language: 'javascript',
            label: 'JavaScript',
            class: 'code-javascript',
          },
          { language: 'xml', label: 'HTML, XML', class: 'code-xml' },
        ],
      },
      video: {
        resizeUnit: '%',
        styles: ['alignLeft', 'alignCenter', 'alignRight'],

        resizeOptions: [
          {
            name: 'videoResize:original',
            value: null,
            label: '100%',
            icon: 'original',
          },
          {
            name: 'videoResize:50',
            value: 50,
            label: '50%',
            icon: 'medium',
          },
          {
            name: 'videoResize:75',
            value: '75',
            label: '75%',
            icon: 'large',
          },
        ],
        toolbar: [
          'videoResize:50',
          'videoResize:75',
          'videoResize:original',
          '|',
          'videoStyle:alignLeft',
          'videoStyle:alignCenter',
          'videoStyle:alignRight',
        ],
      },
      placeholder: this.placeholder,
      language: 'hu',
    };
    if (!this.fontBackgroundColor) {
      var pos = this.config.toolbar.indexOf('fontBackgroundColor');
      this.config.toolbar.splice(pos, 1);
    }
    if (!this.lists) {
      var pos = this.config.toolbar.indexOf('numberedlist');
      this.config.toolbar.splice(pos, 2);
    }
    if (!this.media) {
      var pos = this.config.toolbar.indexOf('mediaEmbed');
      this.config.toolbar.splice(pos, 1);
    }
    if (!this.code) {
      var pos = this.config.toolbar.indexOf('codeblock');
      this.config.toolbar.splice(pos, 1);
    }
    if (!this.table) {
      var pos = this.config.toolbar.indexOf('insertTable');
      this.config.toolbar.splice(pos, 1);
    }
    this.mounted = true;
  },
  beforeDestroy() {
    this.editorInstance = null;
    this.editor = null;
    this.config = null;
  },
  created() {},
  methods: {
    OnEditorReady(editor) {
      this.editorInstance = editor;
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new CKEditorUploadAdapter(loader);
      };
      editor.plugins.get('Notification').on('show:warning', (evt, _data) => {
        if (_data.type == 'warning' && evt.name == 'show:warning:upload') {
          evt.stop();
          NotificationFunctions.ErrorAlert(_data.message, '');
        }
      });
      if (this.minHeight) {
        editor.ui.view.editable.element.style.minHeight = this.minHeight;
      }
      editor.conversion.for('downcast').add(function (dispatcher) {
        dispatcher.on('insert:video', function (evt, data, conversionApi) {
          const viewWriter = conversionApi.writer;
          const $figure = conversionApi.mapper.toViewElement(data.item);
          const $video = $figure.getChild(0);
          viewWriter.setAttribute('playsinline', 'playsinline', $video);
          viewWriter.setAttribute('preload', 'metadata', $video);
          viewWriter.setAttribute('muted', 'muted', $video);
          viewWriter.setAttribute('controls', true, $video);

          // viewWriter.setAttribute('autoplay', true, $video);
          viewWriter.setStyle('width', '100%', $video);
        });
      });
    },
    OnInput(e) {
      this.$emit('input', e);
    },
    OnEditorDestroy(e) {
      this.mounted = false;
    },
  },
  computed: {},
  watch: {},
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(
      'Do you really want to leave? you have unsaved changes!'
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  props: {
    value: {},
    placeholder: {
      type: String,
      default: 'Kezdjen el gépelni...',
    },
    validator: {
      type: Object,
      default: function () {
        return { value: {}, $touch: function () {} };
      },
    },
    fontBackgroundColor: {
      type: Boolean,
      default: () => true,
    },
    lists: {
      type: Boolean,
      default: () => true,
    },
    media: {
      type: Boolean,
      default: () => true,
    },
    code: {
      type: Boolean,
      default: () => true,
    },
    table: {
      type: Boolean,
      default: () => true,
    },
    minHeight: {},
  },
};
</script>

<style scoped>
.error .ck-editor__editable {
  border: 1px solid #dc3545 !important;
  border-radius: 0.25rem !important;
}
</style>

import { hirService } from '../services/HirService';

export class CKEditorUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    let file = await this.loader.file;
    console.log('File size: ', file.size);
    if (file.size > 200 * 1024 * 1024)
      throw 'A fájl mérete meghaladja a megengedett 200 Mbyte-ot';
    var formData = new FormData();
    formData.append('files', file);
    try {
      let result = await hirService.UploadFiles({ formData });
      let uploadedFileUrl = result[0];
      if (
        file.type.toLowerCase().includes('video') &&
        !uploadedFileUrl.includes('#t=')
      ) {
        uploadedFileUrl += '#t=0.001';
      }
      return {
        default: uploadedFileUrl,
      };
    } catch (error) {
      console.log(error);
      throw error.responseJSON?.Message ?? 'A fájl feltöltése nem sikerült';
    }
  }

  abort() {}
}

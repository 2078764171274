import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import ExpandIcon from '../../assets/images/ckeditor/expand.svg';
import CompressIcon from '../../assets/images/ckeditor/compress.svg';

export class FullScreen extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add('fullScreen', locale => {
      const view = new ButtonView(locale);
      let isFullScreen = 0;
      view.set({
        label: 'Teljes képernyő',
        icon: ExpandIcon,
        tooltip: true,
      });

      view.on('execute', () => {
        editor.ui.view.panel.hide();
        if (isFullScreen == 0) {
          editor.sourceElement.parentElement.classList.add(
            'full-screen-ck-input'
          );

          view.set({
            label: 'Normál képernyő',
            icon: CompressIcon,
            tooltip: true,
          });
          isFullScreen = 1;
        } else {
          editor.sourceElement.parentElement.classList.remove(
            'full-screen-ck-input'
          );

          view.set({
            label: 'Teljes képernyő',
            icon: ExpandIcon,
            tooltip: true,
          });
          isFullScreen = 0;
        }
        setTimeout(() => {
          editor.ui.view.panel.show();
          editor.ui.update();
        }, 550);
      });

      return view;
    });
  }
}
